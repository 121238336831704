<template>
    <div id="contact-me">
        <div :class="displayTheme + ' main'">
            <h1 class="title">Contact Me</h1>
            <p>Email: phillipjunior95@gmail.com</p>
            <div class="main-content">
                <div class="contact-form">
                    <form 
                    action="https://formspree.io/f/mqkwdyev"
                    method="POST">
                        <div class="form-fields">
                            <div class="half">
                                <input type="text" id="input-name" 
                                name="name"
                                placeholder="Your Name">
                                <input type="email"
                                name="_replyto" 
                                id="input-email" placeholder="Your Email address">
                                <input type="text" 
                                name="subject"
                                id="input-subject" placeholder="Subject">
                            </div>
                            <div class="half">
                                <textarea name="message" type="text" id="input-message" placeholder="Message"></textarea>
                            </div>  
                        </div>
                        <div class="form-submit">
                            <input type="submit" value="Send" id="input-submit">
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div> 
</template>
<script>
export default {
    name:"contactMe",
    props: {
        nightmode : false
    },
    computed:{
        displayTheme(){
            return this.nightmode ? "theme-dark" : "theme-light"
        }
    }
}
</script>
<style lang="css" scoped>
#contact-me{
    width: auto;
    padding: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}
 
.main{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.title{
    width: fit-content;
    -ms-flex-align: start;
    font-weight: bold;
    margin-bottom: 3rem; 
}

.main-content{
    width: fit-content;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    
}

form {
   max-width: 60rem;
   margin: 0rem auto;
   display: flex;
   flex-flow: column;
   flex-wrap: nowrap;
   justify-content: center;
   align-items: center;

}

.form-fields{
    width: 100%;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.form-submit{
    width: 100%;
}

input, textarea {
    border:0; outline:0;
    padding: 1rem;
    border-radius: 8px;
    display: block;
    width: 100%;
    margin-top: 1rem;
    box-shadow: 0px 0px 1px 1px rgba(0,0,0, 0.1);
    resize: none;
}

textarea {
    height: 12.5rem;
}

input:focus, textarea:focus {
    box-shadow: 0px 0px 0px 2px var(--bs-secondary) !important;
}

#input-submit {
    width: 100%;
    color: white; 
    background: var(--bs-primary);;
    cursor: pointer;
}
  
#input-submit:hover{
    box-shadow: 0px 1px 1px 1px rgba(#aaa, 0.6); 
}

.half {
  width: 45%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 720px){
    .title{
        margin-bottom: 2rem;
    }

    .half {
        width: 100%; 
        margin-bottom: 0; 
    }
}


/* colors */
.theme-dark .title,
.theme-dark p, 
.theme-dark .main-content{
    color: white;
}

.theme-light .title, 
.theme-light p, 
.theme-light .main-content{
    color: black;
}


</style>