<template>
  <div id="app" :class="displayTheme">
    <b-navbar :class="displayTheme" 
    toggleable="lg" 
    :type="nav_mode"
    fixed="top"
    :sticky="true">

      <!-- Navigation header -->
      <b-navbar-brand class="nav-brand_cust">

        <router-link to="/" class="router-link">
          <div id="nav-title-con">
              <img src="./assets/images/logo.png" alt="Lewiking">
            <p :class="displayTheme ">Lewiking</p>
          </div>
        </router-link>
      </b-navbar-brand>

      <div class="mini-nav-btns">
        <!-- nightmode button -->
        <div id="mode-btn-1" class="displayModeSwitch" @click="changeMode">
            <img :src="displaymodeImage" alt="displaymode">
        </div>
      
        <!-- collaspe buttone -->
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      </div>

      <!-- routes -->
      <b-collapse id="nav-collapse"  class="nav-items-cust" is-nav >
        <b-navbar-nav >
            <b-nav-item :class="displayTheme"> 
              <router-link  to="/" class="router-link">
                Home
              </router-link>
            </b-nav-item> 
          <b-nav-item :class="displayTheme" href="#about-me">About</b-nav-item>
          <b-nav-item :class="displayTheme" href="#skills">Skills</b-nav-item>
          <b-nav-item :class="displayTheme" href="#services">Services</b-nav-item>
          <b-nav-item :class="displayTheme" href="#portfolio">Portfolio</b-nav-item>
          <b-nav-item 
            :class="displayTheme" 
            href="https://blog.lewiking.com/"
            target="_blank">Blog</b-nav-item>
          <b-nav-item :class="displayTheme" href="#contact-me">Contact Me</b-nav-item>
        </b-navbar-nav>
      </b-collapse>

      <!-- nightmode button -->
      <div id="mode-btn-2" class="displayModeSwitch" @click="changeMode">
          <img :src="displaymodeImage" alt="displaymode">
      </div>

    </b-navbar>
    <section class="header-design">
      <div class="footer-wave"></div>
    </section>
    <router-view :nightmode="nightmode"/>

    <!-- Copyright bottom -->
    <div class="footer-copyright">
      <p>Copyright © {{copyrightYear}}. All rights reserved.</p>
      <div class="footer-socials">
        <a href="https://www.linkedin.com/in/phillip-l-61b518136/" target="_blank"> 
          <img src="./assets/images/icons/svg/linkedin.svg">
        </a>
        <a href="https://twitter.com/Lewikingg" target="_blank" > 
          <img src="./assets/images/icons/svg/twitter.svg">
        </a>
        <a href="https://github.com/Phillipj1514" target="_blank"> 
          <img src="./assets/images/icons/svg/github.svg">
        </a>
      </div>
    </div>
    <!-- back to top button -->
    <go-top
    :size="50"
    :bg-color="'#4066E0'"/>
  </div>
</template>
<script>
import GoTop from '@inotom/vue-go-top';


export default {
  name:'App',
  components:{
    GoTop
  },
  data(){
    return{
      nightmode:false
    }
  }, 
  computed:{
    displaymodeImage(){
      return !this.nightmode ? require("./assets/images/icons/moon.png") : require("./assets/images/icons/sun.png");
    },

    nav_mode(){
      return this.nightmode ? "dark" : "light"
    },
    displayTheme(){
      return this.nightmode ? "theme-dark" : "theme-light"
    },
    copyrightYear(){
      return new Date().getFullYear()
    }
  },
  methods:{
    changeMode(){
      this.nightmode = !this.nightmode;
    }
  }
}
</script>

<style lang="css">
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
#app {
  width: 100%;
  height: auto;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar-brand a{
  text-decoration: none;
}

#nav-title-con{
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 5rem;
}

#nav-title-con img{
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

#nav-title-con p{
  margin: 0;
  font-weight: bold;
  text-transform: uppercase;
}


.mini-nav-btns{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;   
}

.displayModeSwitch{
  width: 1.5rem;
  height: 1.5rem;
}

#mode-btn-1{
  display: none;
  margin-right: 3rem;
}

#mode-btn-2{
  margin-right: 5rem;
}

.displayModeSwitch img{
  width: 100%;
  height: 100%;
}
.nav-item a{
  text-decoration: none;
}

.nav-item a:hover:not(a.router-link-active:hover){
  text-decoration: none;
}

.nav-brand_cust{
  margin-left: 1rem;
}

.nav-items-cust{
  justify-content: flex-end;
  margin-left: 1rem;
  margin-right: 1.5rem;
}

.footer-copyright{
  width: 100%;
  height: auto;
  background-color: #04050F;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-left: 2rem;
  padding: 0.5rem;
}

.footer-copyright p{
  margin: 0; 
  padding-bottom: 5px;
  color: white;
}

.footer-socials{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 7px;

}

.footer-socials a{
  margin-right: 1rem;
}

.footer-socials a:hover{
  opacity: .8;
}


.footer-socials img{
  width: 1rem;
  height: 1rem;
}

@media only screen and (max-width: 990px){
  #nav-title-con{
    margin-left: 1rem;
  }

  #mode-btn-2{
    display: none;
  }

  #mode-btn-1{
    display:block;
  }

  .nav-items-cust{
    margin-top: 1rem;
    margin-left: 2rem;
  }


}
@media only screen and (max-width: 640px){
  .header-design{
    display: none;
  }
}

@media only screen and (max-width: 400px){
  .footer-copyright p{
    order: 2;
  }

  .footer-socials{
    margin-left: 0rem; 
    order: 1;
  }

  #mode-btn-1{
    margin-right: 2rem;
  }
}


/* Colors */
.theme-dark#app{
  background-color:  var(--bs-dark);;
}

.light-dark#app{
  background-color: white;
}

.theme-dark.navbar{
  background-color:  var(--bs-dark);;
}

.theme-light.navbar{
  background-color: white;
}

#nav-title-con p.theme-dark{
  color: white;
}

#nav-title-con p.theme-light{
  color: black;
}

.theme-dark.nav-item a a.router-link-active{
  color:  var(--bs-primary) !important;
}

.theme-light.nav-item a a.router-link-active{
  color: var(--bs-primary) !important;
}

.theme-dark.nav-item a{
  color: white  !important;
}

.theme-light.nav-item a{
  color: black  !important;
}

.theme-dark.nav-item a:hover:not(a.router-link-active:hover),
.theme-light.nav-item a:hover:not(a.router-link-active:hover){
  opacity: .7;
}

/* footer wave */

.theme-dark .header-design{
  display: none;
}

.theme-light .header-design {
  background-color: #793DB6;
  background-image: linear-gradient(326deg, #793DB6 0%, #2C329E 74%);
  height: 70px;
  text-align: center;
}

div .footer-wave {
  max-width: 102%;
  width: 100%;
  height: 93.9px;
  left: 0;
  z-index: 1;
  bottom: -67px;
  background: url(~@/assets/images/wave-white.png)
    repeat-x;
  animation: wave 10s cubic-bezier(0.44, 0.66, 0.67, 0.37) infinite;
}
  
@keyframes wave {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1440px;
  }
}

</style>
