<template>
    <div id="home-top">
        <div class="holder">
            <socialicons :nightmode="nightmode"/>
            <div :class="displayTheme+' detail-holder'">
                <div class="info">
                    <h1>Hey, I am Phillip Llewellyn </h1>
                    <h4>Software Developer</h4>
                    <p>I am a software developer. I am an undergraduate student of the University of the West Indies. Take a Look.</p>
                    <a href="#contact-me" class="btn btn-primary">
                        <div>
                            <p>Contact Me</p>
                            <img src="@/assets/images/icons/svg/send.svg">
                        </div>
                    </a>
                </div>
                <div id="three-containerr" class="profile-image">
                    <img src="@/assets/images/me-au-de-bico.jpeg" alt="Phillip Freestyling">
                    <div class = "pi-details">
                        <h1>Surprise!!!</h1>
                        <p>The true beauty is hidden in the details 😉</p>
                    </div>
                </div>
            </div>          
        </div>
    </div> 
</template>
<script>
import socialicons from '@/components/top_social_icons.vue'

export default {
    name:"topView",
    components:{
        socialicons
    },
    props: {
        nightmode : false
    },
    computed:{
        displayTheme(){
            return this.nightmode ? "theme-dark" : "theme-light"
        }
    }
}
</script>
<style lang="css" scoped>
.holder{
    width: 100%;
    height: 45rem;
    display: flex;
    flex-wrap: nowrap;
    flex-flow: row;
    justify-content: center;
    padding: 1rem;

}
.detail-holder{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0rem 3rem;
}

.info{
    height: auto;
    width: 28rem;
    padding: 1rem;
}

.info h1{
    font-weight: bold;
}
.profile-image{
    height: 20rem;
    width: 20rem;
    margin-left: 5rem;
    margin-right: 0rem;
    text-align: center; 
    position: relative;
    cursor: pointer;  
}
.profile-image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
}

.pi-details{
    width: 70%;
    height: 80%;  
    padding: 5% 8%;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateY(90deg);
    transform-origin: 50%;
    background: rgba(255,255,255,0.9);  
    opacity: 0;
    transition: all 0.4s ease-in;
    border-radius: 0.5rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.profile-image:hover .pi-details{
    transform: translate(-50%, -50%) rotateY(0deg);
    opacity: 1;
}

/*GrayScale*/
.profile-image:hover  img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.profile-image img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

@media only screen and (max-width: 1038px){
    .profile-image{
        margin-left: 1rem; 
    }
}

@media only screen and (max-width: 975px){
    .info{
        order: 2;
    }
    .profile-image{
        order: 1;
    }
}

@media only screen and (max-width: 720px){
    .info{
        width: 100%;
        padding-top: 1.5rem;
        padding-right: 2rem;
    }
    .profile-image{
        width: 15rem;
        height: 15rem;
    }

    .detail-holder{
        margin: 0rem 2.5rem;
    }
}

@media only screen and (max-width: 480px){
     .detail-holder{
        margin: 0rem 1rem;
    }
}

/* colors */

.theme-dark.detail-holder{
    color: white;
}

.theme-light.detail-holder{
    color: black;
}

</style>