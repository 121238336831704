<template>
    <div id="latest-blog">
        <div :class="displayTheme + ' main'">
            <h1 class="title">Latest Blog</h1>
            <div class="main-content">
                <img src="@/assets/images/blog/capstone-project.png">
                <div class="overlay"></div>
                <div class="content">
                    <div class="blog-details">
                        <h3>UWI Final Year Project</h3>
                        <p>We created an application that helps users to find specific locations on campus that a regular navigation service can’t provide. It was done with Augmented reality. </p>
                        <a href="https://blog.lewiking.com/uwi-final-year-project/" 
                        target="_blank" 
                        class="btn btn-primary"> Read The Blog</a>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
export default {
    name:"latestBlog",
    props: {
        nightmode : false
    },
    computed:{
        displayTheme(){
            return this.nightmode ? "theme-dark" : "theme-light"
        }
    }
}
</script>
<style lang="css" scoped>
#latest-blog{
    width: auto;
    padding: 0rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
}
 
.main{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.title{
    width: fit-content;
    -ms-flex-align: start;
    font-weight: bold;
    margin-bottom: 3rem; 
}

.main-content{
    width: 100%;
    height: 40rem;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;    
}
.main-content img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    opacity: .8;
    z-index: 1;
}

.main-content .content{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-details{
    width: 50%;
    height:auto;
    background-color: white;
    color: black;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 2rem;
}

@media only screen and (max-width: 720px){
    .title{
        margin-bottom: 2rem;
    }

    .blog-details{
        width: 25rem;
    }

}


@media only screen and (max-width: 480px){
    .blog-details{
        width: 20rem;
    }

}

/* colors */
.theme-dark .title, .theme-dark .main-content{
    color: white;
}

.theme-light .title, .theme-light .main-content{
    color: black;
}
</style>