<template>
    <div id="services">
        <div :class="displayTheme + ' main'">
            <h1 class="title">Services</h1>
            <div class="main-content">
                <div class="service-card">
                    <img src="@/assets/images/icons/svg/website-development.svg">
                    <h4> Website Development</h4>
                    <a href="#contact-me" class="btn btn-primary"><p> I want to know more.</p></a> 
                </div>

                <div class="service-card">
                    <img src="@/assets/images/icons/svg/mobile-development.svg">
                    <h4> Mobile and Web App Development</h4>
                    <a href="#contact-me" class="btn btn-primary"><p> I want to know more.</p></a> 
                </div>
                
                <div class="service-card">
                    <img src="@/assets/images/icons/svg/consult.svg">
                    <h4> Technical consultant</h4>
                    <a href="#contact-me" class="btn btn-primary"><p> I want to know more.</p></a> 
                </div>
                
            </div>
        </div>
    </div> 
</template>
<script>
export default {
    name:"services",
    props: {
        nightmode : false
    },
    computed:{
        displayTheme(){
            return this.nightmode ? "theme-dark" : "theme-light"
        }
    }
}
</script>
<style lang="css" scoped>
#services{
    width: auto;
    padding: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}
 
.main{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.title{
    width: fit-content;
    -ms-flex-align: start;
    font-weight: bold;
    margin-bottom: 3rem; 
}

.main-content{
    width: fit-content;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    
}

.service-card{
    width: 20rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem 2rem;
    border-radius: 1rem;
    text-align: center;
}

.service-card img{
    width: 3rem;
    height: auto;
}

.service-card h4{
    margin: 1rem 1.5rem;
}

.btn{
    padding: 0.5rem !important;  
}

.btn p{
    margin: 0; 
}

@media only screen and (max-width: 720px){
    .title{
        margin-bottom: 2rem;
    }

    .service-card{
        width: 15rem;
        height: 15rem;   
        margin: 0.5rem 0.5rem;
    }

}

@media only screen and (max-width: 480px){
    .service-card{  
        margin: 0.5rem 0rem;
    }

}


/* colors */
.theme-dark .title, .theme-dark .main-content{
    color: white;
}

.theme-light .title, .theme-light .main-content{
    color: black;
}

.theme-dark .main-content .service-card{
    background-color: var(--bs-secondary);
    color: black;

}

.theme-light .main-content .service-card{
    background-color: var(--bs-secondary);
}
</style>