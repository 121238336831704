<template>
    <div id="testimonial">
        <div :class="displayTheme + ' main'">
            <h1 class="title">Testimonials</h1>
            <div class="main-content">
                <div class="testimonies">
                    <div class="testimony">
                        <h4>Name of the person</h4>
                        <p>What they have to say</p>
                    </div>

                    <div class="testimony">
                        <h4>Name of the person</h4>
                        <p>What they have to say</p>
                    </div>

                    <div class="testimony">
                        <h4>Name of the person</h4>
                        <p>What they have to say</p>
                    </div>

                </div>
            </div>
        </div>
    </div> 
</template>
<script>
export default {
    name:"testimonial",
    props: {
        nightmode : false
    },
    computed:{
        displayTheme(){
            return this.nightmode ? "theme-dark" : "theme-light"
        }
    }
}
</script>
<style lang="css" scoped>
#testimonial{
    width: auto;
    padding: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}
 
.main{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.title{
    width: fit-content;
    -ms-flex-align: start;
    font-weight: bold;
    margin-bottom: 3rem; 
}

.main-content{
    width: fit-content;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    
}

.testimonies{
    width: auto;
    height: auto;
    padding: 1rem;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.testimony{
    width :15rem;
    height: 7rem;
    padding: 0.5rem;
    background-color: #C6A9E2;
    border-radius: 0.5rem;
    margin: 0.5rem 1rem;
}

@media only screen and (max-width: 720px){
    .title{
        margin-bottom: 2rem;
    }

}

/* colors */
.theme-dark .title, .theme-dark .main-content{
    color: white;
}

.theme-light .title, .theme-light .main-content{
    color: black;
}
</style>