<template>
    <div id="portfolio">
        <div :class="displayTheme + ' main'">
            <h1 class="title">Portfolio</h1>
            <div class="main-content">
                <hooper class="slide-contianer" :itemsToShow="slideItemsToShow">

                     <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/farmcredibly.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">Farm Credibly</h4>
                                <p>Made with Drupal. A company focus on developing Tech for agriculture</p>
                                <a href="https://farmcredibly.com/" 
                                class="btn btn-primary"
                                target="_blank" 
                                >Visit</a>
                            </div>  
                        </div>
                    </slide>

                    <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/uwi-ar-maps.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">UWI AR Maps</h4>
                                <p>Made with Flask and Vue. My final year capstone project. Get directions in Augmented reality</p>
                                <a href="https://uwi-capstone-vfalcons.github.io/" 
                                class="btn btn-primary"
                                target="_blank" 
                                >Visit</a>
                            </div>  
                        </div>
                    </slide>

                    <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/pallisadoes.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">Quito / Tallawa</h4>
                                <p>Made with Flutter and Plone API, now on NodeJs. I contributed as a Palisadoes Calico Intern</p>
                                <a href="https://github.com/PalisadoesFoundation/talawa" 
                                class="btn btn-primary"
                                target="_blank" 
                                >Visit</a>
                            </div>  
                        </div>
                    </slide>

                    <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/phill-welding.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">Phillip's Welding</h4>
                                <p>Made with Wix. A portfolio website for my father's business. Take a look.</p>
                                <a href="https://phillipwork.wixsite.com/welding" 
                                class="btn btn-primary"
                                target="_blank" 
                                >Visit</a>
                            </div>  
                        </div>
                    </slide>

                    <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/lewiking-blog.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">Lewiking Blog</h4>
                                <p>Made with Ghost. My personal blog to share my discoveries and experiences.</p>
                                <a href="https://blog.lewiking.com/" 
                                class="btn btn-primary"
                                target="_blank" 
                                >Visit</a>
                            </div>  
                        </div>
                    </slide>

                    <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/lewiking.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">Lewiking.com</h4>
                                <p>Made with Vue. Your here enjoying my portfolio.</p>
                            </div>  
                        </div>
                    </slide>

                     <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/calabar-robotics.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">Calabar Robotics</h4>
                                <p>Made with wordpress. It website to share the club accomplishments.</p>
                                <a href="https://cbarrobotics.wordpress.com/" 
                                class="btn btn-primary"
                                target="_blank" 
                                >Visit</a>
                            </div>  
                        </div>
                    </slide>
                    
                    <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/GCI.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">Google Code In Finalist</h4>
                                <p>I contributed to drupal during my Christmas holiday.</p>
                                <a href="https://codein.withgoogle.com/archive/2015/" 
                                class="btn btn-primary"
                                target="_blank" 
                                >Visit</a>
                            </div>  
                        </div>
                    </slide>

                    <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/ganja-gram.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">Ganja Gram Minification</h4>
                                <p>Made with native android. I converted the app to a native android</p>
                                <a href="https://blog.lewiking.com/minifying-android-app/" 
                                class="btn btn-primary"
                                target="_blank" 
                                >Visit</a>
                            </div>  
                        </div>
                    </slide>

                    <slide>
                        <div class="portfolio-card">
                            <div class="card-image">
                                <img src="@/assets/images/portfolio/patwa-org.png">
                            </div>
                            <div class="card-content">
                                <h4 class="c-title">Patwa.org</h4>
                                <p>Made with Drupal. A website that can help people to understand the Jamaican Patois language.</p>
                                <a href="http://patwa.org/" 
                                target="_blank" 
                                class="btn btn-primary">Visit</a>
                            </div>  
                        </div>
                    </slide>
        
                    <!-- Slider Navigation -->
                    <hooper-navigation slot="hooper-addons"></hooper-navigation>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                </hooper>
            </div>
        </div>
    </div> 
</template>
<script>
import { 
    Hooper, 
    Slide,
    Progress as HooperProgress,
    Pagination as HooperPagination,
    Navigation as HooperNavigation 
} from 'hooper';
import 'hooper/dist/hooper.css';


export default {
    name:"portfolio",
    components: {
        // VueperSlides, 
        // VueperSlide
        Hooper,
        Slide,
        HooperProgress,
        HooperPagination,
        HooperNavigation
    },
    props: {
        nightmode : false
    },
    data(){
        return {
            window: {
                width: 0,
                height: 0
            }
        }
    },
    computed:{
        displayTheme(){
            return this.nightmode ? "theme-dark" : "theme-light"
        },
        slideItemsToShow(){
            if( this.window.width >= 1460){
                return 3
            }else if(this.window.width >= 720){
                return 2
            }
            return 1
        },
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
   
}
</script>
<style lang="css" scoped>
#portfolio{
    width: auto;
    padding: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;

}

.main{
    width: fit-content;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.title{
    width: fit-content;
    -ms-flex-align: start;
    font-weight: bold;
    margin-bottom: 3rem; 
}

.main-content{
    width: 100%;
    padding: 0rem 10rem; 
}

.slide-contianer{
    height: 25rem;
    padding: 0rem 5rem;
}

.portfolio-card{
    width: 22rem;
    height: 25rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.card-image{
    width: 100%;
    height: 50%;   
}

.card-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
}

.card-content{
    width: 100%;
    height: 60%;
    padding: 1rem 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.c-title{
    font-weight: bold;
}

.card-content p{
    word-break: break-word;
}

@media only screen and (max-width: 1460px){
       .main-content{
        padding: 0rem; 
    }
}

@media only screen and (max-width: 720px){
    #portfolio{
        padding: 0.5rem;
    }

    .main-content{
        padding: 0rem; 
    }

    .title{
        margin-bottom: 2rem;
    }

    .slide-contianer{
        padding: 0rem 3rem;
    }

    .card-image{
        height: 40%;   
    }

}

@media only screen and (max-width: 480px){
    .portfolio-card{
        width: 15rem;
        height: 25rem;
    }   
    .c-title{
        font-size: medium;
    }

    .card-content p{
        margin-bottom: 0.5rem;
    }

    
}


/* colors */
.theme-dark .title, .theme-dark .main-content{
    color: white;
}

.theme-light .title, .theme-light .main-content{
    color: black;
}

.theme-dark .card-content{
    color: black; 
}

.theme-dark .card-content{
    background-color: var(--bs-secondary);
}

.theme-light .card-content{
    background-color: var(--bs-secondary);
}


</style>
<style>
/* the slider styling */
.hooper-next svg.icon, .hooper-prev svg.icon{
    width: 3.5rem;
    height: 3.5rem;
}

.theme-dark .hooper-next svg.icon, 
.theme-dark .hooper-prev svg.icon{
    fill: white;
}

@media only screen and (max-width: 720px){
    .hooper-next svg.icon, .hooper-prev svg.icon{
        width: 2.5rem;
        height: 2.5rem;
    }
}

@media only screen and (max-width: 480px){
    .hooper-next , .hooper-prev{
        padding: 0.3rem;
    }
}
</style>
