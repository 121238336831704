<template>
    <div id="about-me">
        <div :class="displayTheme + ' main'">
            <h1 class="title">About Me</h1>
            <div class="main-content">
                <div class="image-holder">
                    <img src="@/assets/images/default-profile.jpg">
                </div>
                <div class="content-holder">
                    <h4 class="quote">“You'll never know until you try.” ― Davee Jones</h4>
                    <p>I believe that you should never be afraid to take on challenges, but at the same time, be smart about how you do it. I always try my best to embrace technologies and its potential to change the world. I try to be innovative, secure, creative, relevant and social in all that I do. I work on software for robots and humans. lol.</p>
                    <p class="years-exp"> I have {{yearsOfExperience}}+ years of experience </p>
                    <div class="documents-btn">
                        <a href="https://drive.google.com/file/d/15PtRd0io6Pp_4lhsmgP5wwcbIupvjU2F/view?usp=sharing"
                        target="_blank"
                        class="btn btn-primary"> Download CV</a>
                        <a href="https://drive.google.com/file/d/15MSKLeTVteY2a0tgBbAzF8J2iFh0qc51/view?usp=sharing"
                        target="_blank"
                        class="btn btn-primary">Download Resume</a>
                    </div>

                </div>
            </div>
        </div>
    </div> 
</template>
<script>
export default {
    name:"aboutMe",
    props: {
        nightmode : false
    },
    computed:{
        displayTheme(){
            return this.nightmode ? "theme-dark" : "theme-light"
        },
        yearsOfExperience(){
            return new Date().getFullYear() - 2014;
        }
    }
}
</script>
<style lang="css" scoped>
#about-me{
    width: auto;
    padding: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;

}

.main{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.title{
    width: fit-content;
    font-weight: bold;
    margin-bottom: 3rem;
}

.main-content{
    width: fit-content;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0rem 2rem;
}

.image-holder{
    width: 20rem;
    height: 20rem;
    margin-right: 6rem;
}

.image-holder img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
}

.content-holder{
    width: 35rem;
    padding: 1rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.content-holder .years-exp{
    font-weight: bold;
    font-size: large;
}

.content-holder .quote{
    font-style: italic;
}

.documents-btn{
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.documents-btn a{
    margin-top: 1rem;
}

@media only screen and (max-width: 720px){
    .image-holder{
        margin-right: 0rem;
        margin-bottom: 1rem;

    }
    .content-holder{
        width: auto;
    }
}

@media only screen and (max-width: 480px){
    #about-me{
        padding: 1rem;
    }
}

/* colors */
.theme-dark .title, .theme-dark .main-content{
    color: white;
}

.theme-light .title, .theme-light .main-content{
    color: black;
}
</style>