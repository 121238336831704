<template>
  <div class="main">
    <topView :nightmode="nightmode"/>
    <aboutme :nightmode="nightmode"/>
    <skills :nightmode="nightmode"/>
    <services :nightmode="nightmode"/>
    <portfolio :nightmode="nightmode"/>
    <latestblog :nightmode="nightmode"/>
    <!-- <testimonial :nightmode="nightmode"/> -->
    <contactme :nightmode="nightmode"/>
  </div> 
</template>

<script>
// @ is an alias to /src
import topView from '@/components/top_section.vue'
import aboutme from '@/components/about_me_section.vue'
import skills from '@/components/skills_section.vue'
import services from '@/components/services_section.vue'
import portfolio from '@/components/portfolio_section.vue'
import latestblog from '@/components/latest_blog_section.vue'
import testimonial from '@/components/testimonial_section.vue'
import contactme from '@/components/contact_me_section.vue'



export default {
  name: 'Home',
  components: {
    topView,
    aboutme,
    skills,
    services,
    portfolio,
    latestblog,
    testimonial,
    contactme
  },
  props: {
    nightmode : false
  },
  computed:{
    currentMode(){
      return this.nightmode ? "on": "off"
    }
  }
}
</script>
<style lang="css">
.main{
  width: 100%;
  height: auto;
}
</style>
