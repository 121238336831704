<template>
    <div id="skills">
        <div :class="displayTheme + ' main'">
            <h1 class="title">Skills</h1>
            <div class="main-content">
                <div class="skill-section">
                    <h4>Mobile and web development</h4>
                    <div class="p-bar">
                        <p class="p-bar-title">HTML, CSS, JS</p>
                        <b-progress class="p-bar-line" :value="95" :max="max" variant="primary" show-progress animated></b-progress>
                    </div>
                    <div class="p-bar">
                        <p class="p-bar-title">SQL, OO db</p>
                        <b-progress class="p-bar-line" :value="60" :max="max" variant="primary" show-progress animated></b-progress>
                    </div>
                    <div class="p-bar">
                        <p class="p-bar-title">Drupal, Wordpress</p>
                        <b-progress class="p-bar-line" :value="70" :max="max" variant="primary" show-progress animated></b-progress>
                    </div>
    
                    <div class="p-bar">
                        <p class="p-bar-title">Flutter,  Android</p>
                        <b-progress class="p-bar-line" :value="80" :max="max" variant="primary" show-progress animated></b-progress>
                    </div>
                 
                    <div class="p-bar">
                        <p class="p-bar-title">Vue, Angular</p>
                        <b-progress class="p-bar-line" :value="80" :max="max" variant="primary" show-progress animated></b-progress>
                    </div>

                </div>
                <div class="skill-section">
                    <h4>AI, Data Science, Robotics</h4>
                    <div class="p-bar">
                        <p class="p-bar-title">Python, R </p>
                        <b-progress class="p-bar-line" :value="70" :max="max" variant="primary" show-progress animated></b-progress>
                    </div>
                    <div class="p-bar">
                        <p class="p-bar-title">Arduino, Raspberry Pie</p>
                        <b-progress class="p-bar-line" :value="60" :max="max" variant="primary" show-progress animated></b-progress>
                    </div>
                     <div class="p-bar">
                        <p class="p-bar-title">FIRST Robotics Coach</p>
                        <b-progress class="p-bar-line" :value="85" :max="max" variant="primary" show-progress animated></b-progress>
                    </div>
                </div>
            </div>

        </div>
    </div> 
</template>
<script>
export default {
    name:"skills",
    props: {
        nightmode : false
    },
    data() {
      return {
        value: 100,
        max: 100
      }
    },
    computed:{
        displayTheme(){
            return this.nightmode ? "theme-dark" : "theme-light"
        }
    },
    methods: {
        skillsbar(){
        }
    }
}
</script>
<style lang="css" scoped>
#skills{
    width: auto;
    padding: 2rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}

.main{
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.title{
    width: fit-content;
    font-weight: bold;
    margin-bottom: 3rem;
}

.main-content{
    width: fit-content;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    padding: 0rem 1rem;

}

.skill-section{
    width: 35rem;
    padding: 1rem 2rem;
}

.skill-section h4{
    font-style: italic;
}

.p-bar{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0.4rem 0rem;
}

.p-bar-title{
    width: 40%;
    margin: 0;
    padding: 0rem 0.5rem;
}

.p-bar-line{
    width:60%;
}


@media only screen and (max-width: 720px){
    .title{
        margin-bottom: 2rem;
    }

    .skill-section{
        width: 100%;
        padding: 1rem 0rem;

    }
}

@media only screen and (max-width: 480px){
    #skills{
        padding: 1rem;
    }
}

/* colors */
.theme-dark .title, .theme-dark .main-content{
    color: white;
}

.theme-light .title, .theme-light .main-content{
    color: black;
}
</style>