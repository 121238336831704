<template>
    <div :class="displayTheme+' social-icons'">
        <a href="https://github.com/Phillipj1514" target="_blank">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <g>
                    <g>
                        <path d="M255.968,5.329C114.624,5.329,0,120.401,0,262.353c0,113.536,73.344,209.856,175.104,243.872
                            c12.8,2.368,17.472-5.568,17.472-12.384c0-6.112-0.224-22.272-0.352-43.712c-71.2,15.52-86.24-34.464-86.24-34.464
                            c-11.616-29.696-28.416-37.6-28.416-37.6c-23.264-15.936,1.728-15.616,1.728-15.616c25.696,1.824,39.2,26.496,39.2,26.496
                            c22.848,39.264,59.936,27.936,74.528,21.344c2.304-16.608,8.928-27.936,16.256-34.368
                            c-56.832-6.496-116.608-28.544-116.608-127.008c0-28.064,9.984-51.008,26.368-68.992c-2.656-6.496-11.424-32.64,2.496-68
                            c0,0,21.504-6.912,70.4,26.336c20.416-5.696,42.304-8.544,64.096-8.64c21.728,0.128,43.648,2.944,64.096,8.672
                            c48.864-33.248,70.336-26.336,70.336-26.336c13.952,35.392,5.184,61.504,2.56,68c16.416,17.984,26.304,40.928,26.304,68.992
                            c0,98.72-59.84,120.448-116.864,126.816c9.184,7.936,17.376,23.616,17.376,47.584c0,34.368-0.32,62.08-0.32,70.496
                            c0,6.88,4.608,14.88,17.6,12.352C438.72,472.145,512,375.857,512,262.353C512,120.401,397.376,5.329,255.968,5.329z"/>
                    </g>
                </g>
            </svg>
        </a>
        <a href="https://www.linkedin.com/in/phillip-llewellyn-61b518136/" target="_blank">
            <svg id="Capa_1" enable-background="new 0 0 512 512"  viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m7.813 509.935h132.571v-348.613h-132.571zm30-318.613h72.571v288.612h-72.571z"/><path d="m74.099 2.065c-40.859 0-74.099 33.241-74.099 74.099s33.24 74.1 74.099 74.1 74.1-33.241 74.1-74.1-33.242-74.099-74.1-74.099zm-44.099 74.099c0-24.316 19.782-44.099 44.099-44.099s44.1 19.782 44.1 44.099-19.783 44.1-44.1 44.1-44.099-19.784-44.099-44.1z"/><path d="m511.679 270.79c-4.718-67.855-61.318-120.144-131.489-120.144-31.387 0-61.016 10.912-84.361 30.274v-19.6h-127.03v348.613h132.572v-190.664c0-21.488 17.481-38.97 38.97-38.97 21.487 0 38.969 17.482 38.969 38.979l.128 190.656h132.562v-238.822zm-240.308 209.145h-72.572v-288.614h67.029v42.847l24.005.138 4.46-6.924c18.85-29.265 50.961-46.735 85.897-46.735 55.836 0 100.543 42.602 101.78 96.985l.03 202.303h-72.582l-.108-160.666c0-38.03-30.94-68.97-68.969-68.97-38.03 0-68.97 30.94-68.97 68.97z"/></g></svg>
        </a>
        <a href="https://twitter.com/Lewikingg" target="_blank">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                <g>
                    <g>
                        <path d="M507.413,93.394c-3.709-2.51-8.607-2.383-12.174,0.327c-3.612,2.735-9.474,5.087-16.138,7.016
                            c18.245-21.301,18.623-35.541,18.408-38.893c-0.245-3.801-2.541-7.168-5.985-8.791c-3.459-1.612-7.51-1.23-10.587,1.005
                            c-21.893,15.908-43.689,19.373-56.791,19.76c-20.337-19.342-46.704-29.944-74.74-29.944c-60.271,0-109.307,49.684-109.307,110.751
                            c0,4.944,0.327,9.878,0.969,14.771C138.176,167.645,54.665,69.155,53.803,68.119c-2.184-2.617-5.5-4.041-8.929-3.714
                            c-3.398,0.296-6.444,2.235-8.148,5.189c-29.005,50.322-11.286,94.725,6.505,121.327c-1.837-1.092-3.342-2.097-4.372-2.857
                            c-3.143-2.337-7.337-2.725-10.852-0.995c-3.521,1.735-5.771,5.286-5.837,9.209c-0.786,48.255,21.764,76.49,43.674,92.49
                            c-2.372,0.327-4.597,1.459-6.266,3.276c-2.51,2.724-3.393,6.576-2.311,10.122c15.194,49.735,52.041,67.352,76.373,73.587
                            c-49.22,37.138-120.557,25.016-121.348,24.867c-4.73-0.831-9.464,1.663-11.408,6.082c-1.939,4.413-0.612,9.587,3.225,12.51
                            c52.464,40.041,115.21,48.913,160.53,48.913c34.272,0,58.573-5.077,60.91-5.582c228.617-54.179,235.864-263.063,235.394-298.66
                            c42.888-39.929,49.633-55.255,50.684-59.067C512.811,100.502,511.117,95.91,507.413,93.394z M443.283,151.752
                            c-2.33,2.143-3.56,5.235-3.346,8.398c0.036,0.561,3.536,57.179-21.694,120.266c-33.709,84.291-100.164,138.725-197.307,161.746
                            c-1.041,0.219-90.905,18.831-169.792-18.689c33.725-1.414,80.429-10.913,113.292-47.806c2.745-3.077,3.398-7.833,1.709-11.593
                            c-1.689-3.75-5.439-6.51-9.551-6.51c-0.02,0-0.041,0-0.071,0c-2.76,0-50.337-0.357-73.133-46.306
                            c9.219,0.398,20.24-0.145,29.122-4.237c4.092-1.888,6.51-6.1,6.005-10.574c-0.505-4.475-3.821-8.079-8.23-9.008
                            c-2.556-0.541-57.649-12.836-66.143-72.693c8.464,3.526,19.015,6.257,29.51,4.685c4.031-0.602,7.332-3.5,8.474-7.413
                            c1.138-3.908-0.107-8.13-3.184-10.809c-2.383-2.07-54.327-48.273-30.541-107.973c28.158,29.332,108.46,102.368,205.833,96.786
                            c3.107-0.179,5.975-1.74,7.82-4.25c1.843-2.51,2.471-5.709,1.71-8.728c-1.837-7.316-2.77-14.857-2.77-22.418
                            c0-49.546,39.658-89.853,88.409-89.853c23.842,0,46.203,9.515,62.97,26.796c1.923,1.985,4.556,3.122,7.322,3.174
                            c9.658,0.092,25.561-0.949,43.531-7.633c-5.359,6.275-12.852,13.622-23.332,21.852c-3.622,2.847-4.954,7.735-3.276,12.026
                            c1.684,4.301,6.056,7.02,10.566,6.607c2.112-0.168,12.352-1.071,24.352-3.505C464.662,131.4,455.494,140.523,443.283,151.752z"/>
                    </g>
                </g>
            </svg>
        </a>
    </div>
</template>
<script>
export default {
    name:"socialIcons",
    props: {
        nightmode : false
    },
    computed:{
        displayTheme(){
            return this.nightmode ? "theme-dark" : "theme-light"
        }
    }
}
</script>
<style scoped>
.social-icons{
    width: 4rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 1rem;
}

.social-icons svg{
    padding: 0.5rem 0rem;

}

.theme-dark.social-icons svg{
    fill: white;
}

.theme-light.social-icons svg{
    fill: var(--bs-primary);
}

.theme-light.social-icons svg:hover,
.theme-dark.social-icons svg:hover{
    opacity: .8;
}

@media only screen and (max-width: 720px){
    .social-icons{
        width: 7rem;
        justify-content: start;
        padding: 0.5rem;
        padding-top: 5rem;

    }
}

@media only screen and (max-width: 480px){
    .social-icons{
        width: 8rem;
        padding: 0rem;
        padding-top: 5rem;

    }
}
</style>